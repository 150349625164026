<script>
import axios from "axios";
import moment from "moment/moment";

export default {
  name: "CallUpdateForm",
  emits: ['onSubmit'],
  props: ['callId'],
  data() {
    return {
      formData: {
        message: null,
        call_id: this.callId,
        date_time: null,
        advisory_type: null,
      },
      loading: false,
      advisory_options: [
        {name: "None", value: null},
        {name: "MVC", value: 1},
        {name: "Road Closure", value: 2},
        {name: "Traffic Control", value: 3},
        {name: "Testing", value: -1},
      ]
    }
  },
  methods: {
    submitForm() {
      this.loading = true;
      this.formData.date_time =  moment();
      axios.post('/call/createUpdate', this.formData)
          .then(() => {
            this.$emit('onSubmit');
            this.formData.message = "";
          })
          .finally(() => {
            this.loading = false;
          });
    }
  }
}
</script>

<template>
  <div class="card col m-2">
     <div class="card-header">
        <h5 class="text-start">Add Update</h5>
     </div>
    <div class="card-body text-start">
      <div class="mb-4">
        <label class="form-label">
            Message
        </label>
        <textarea v-model="formData.message" class="form-control" rows="3"></textarea>
      </div>
      <div class="mb-4 row">
        <label class="form-label col-2">
          Advisory:
        </label>
        <select class="form-control col"
                   id="call_type" name="call_type" v-model="formData.advisory_type">
          <option :value="type.value" v-for="type in advisory_options" v-bind:key="type">{{type.name}}</option>
        </select>
      </div>
      <div class="text-end">
        <button class="btn btn-primary" @click="submitForm">
          <span v-if="loading" class="spinner-border spinner-border-sm"></span>
          <span v-else>Save</span>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>